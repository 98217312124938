import React from "react";
import "./contactinfo.css";

function ContactInfo() {
  return (
    <div className="container-wrapper contact">
      <div className="body-style contactBody">
        <div className="sub-content subcontent">
          <p className="p-style"><b>Office Number:</b></p>
          <p className="p-style">
            +65 63421527 <br></br> <br></br>
            homestay@singnet.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
